export const socialLogin = (social: "GOOGLE" | "FACEBOOK" | "APPLE") => {
  const runtimeConfig = useRuntimeConfig()

  const route = useRoute()
  const socialRedirect: any = useStatefulCookie("socialRedirect", {
    default: () => "",
    maxAge: 600
  })
  if (route) {
    socialRedirect.value = route.fullPath
  }

  const providers: Record<string, string> = {
    GOOGLE: "Google",
    FACEBOOK: "Facebook",
    APPLE: "SignInWithApple"
  }

  const { CONGITO_BASE_URL, BASE_URL_APP, CONGITO_SOCIAL_LOGIN_CLIENT_ID } =
    runtimeConfig.public

  const generateSocialLoginURL = (provider: string) =>
    `${CONGITO_BASE_URL}/oauth2/authorize?identity_provider=${provider}&redirect_uri=${BASE_URL_APP}/social-login&response_type=CODE&client_id=${CONGITO_SOCIAL_LOGIN_CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid phone profile`

  const provider = providers[social]
  return generateSocialLoginURL(provider)
}
